<template>
  <v-container>
    <v-row class="mt-5 justify-center">
      <v-col cols="12" md="7" lg="5" v-if="valid">
        <v-card class="mb-0">
          <v-card-text class="p-5">
            <h2 class="mb-5">{{ $t('received invitation from') }} {{ sender }} {{ $t('to') }} "{{ orgName }}".</h2>
            <v-btn block color="success" v-on:click="acceptInvite" id="accept-invite" class="my-4">{{
              $t('accept')
            }}</v-btn>
            <v-btn block color="error" v-on:click="declineInvite" id="decline-invite" class="my-4">{{
              $t('decline')
            }}</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('user');
import makeOrgService from '@/services/api/org';

let orgService;

export default {
  data() {
    return {
      orgName: '',
      valid: false,
      sender: '',
    };
  },
  created() {
    const handle = this.$route.params.handle;
    const token = this.$route.params.token;
    orgService = makeOrgService(this.$api);
    orgService
      .validateInvite({ handle, token })
      .then((response) => {
        this.valid = true;
        this.orgName = response.data.orgName;
        this.sender = response.data.sender.firstName + ' ' + response.data.sender.lastName;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          return this.$router.replace('/login');
        }
        this.$swal({
          title: error.response?.data?.error ?? error.message,
          icon: 'error',
          showConfirmButton: false,
          position: 'center',
          timer: 2000,
          toast: true,
        });
        this.$router.replace('/');
      });
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  methods: {
    acceptInvite() {
      orgService
        .acceptInvite({
          handle: this.$route.params.handle,
          token: this.$route.params.token,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$swal({
              title: this.$t('acceptedInvitation'),
              icon: 'success',
              showConfirmButton: false,
              position: 'top-center',
              timer: 2000,
              toast: true,
            }).then(() => {
              this.$router.replace('/');
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: error,
            icon: 'error',
            showConfirmButton: false,
            position: 'top-center',
            timer: 2000,
            toast: true,
          });
        });
    },
    declineInvite() {
      return alert('rejected');

      // orgService
      //   .declineInvite({
      //     handle: this.$route.params.handle,
      //     token: this.$route.params.token,
      //   })
      //   .then((response) => {
      //     if (response.status === 200) {
      //       this.$swal({
      //         title: 'Declined invitation',
      //         icon: 'warning',
      //         showConfirmButton: false,
      //         position: 'top-center',
      //         timer: 2000,
      //         toast: true,
      //       }).then(() => {
      //         this.$router.replace('/');
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
  },
};
</script>